import React from "react";
import logo from "../images/logo.svg";
import { useMenuContext } from "../providers/menuProvider";
import TransitionLink from "gatsby-plugin-transition-link";

const Header = () => {
  const [openMenu] = useMenuContext();
  const menuLinks = [
    {
      name: "about",
      link: "/about",
    },
    {
      name: "services & pricing",
      link: "/services",
    },
    {
      name: "contact",
      link: "/contact",
    },
  ];

  return (
    <header className="fixed w-full top-0 z-40 flex justify-center">
      <div className="header-background bg-max absolute inset-0 bg-repeat-x bg-bottom" />
      <div className="container mx-auto py-4 px-6 mb-6 z-50 flex justify-between items-center">
        <TransitionLink
          to="/"
          exit={{
            length: 0.5,
            state: { opacity: 0, y: 10 },
          }}
          entry={{
            delay: 0.5,
            state: { opacity: 0, y: 10 },
          }}
        >
          <img
            className="h-12 md:h-full"
            src={logo}
            alt="Halo Hair Derbyshire Logo"
          />
        </TransitionLink>
        <button className="md:hidden text-lg" onClick={() => openMenu()}>
          menu
        </button>
        <nav className="hidden md:block">
          <ul className="flex">
            {menuLinks.map((link) => (
              <li key={link.name} className="ml-14">
                <TransitionLink
                  to={link.link}
                  exit={{
                    length: 0.5,
                    state: { opacity: 0, y: 10 },
                  }}
                  entry={{
                    delay: 0.5,
                    state: { opacity: 0, y: 10 },
                  }}
                  className="hover:bg-dark-salmon transition duration-500 ease-in-out"
                >
                  {link.name}
                </TransitionLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
