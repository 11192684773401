import * as React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";
import { motion } from "framer-motion";
import { ENTERING, EXITING } from "../constants/transition-states";

const Layout = ({ children, transitionStatus }) => (
  <div className="relative min-h-screen flex flex-col justify-center">
    <Header />
    <main className="min-w-full h-full pb-36 pt-20 md:pb-24">{children}</main>
    <motion.div
      initial={{ opacity: 0 }}
      animate={transitionStatus === EXITING ? { opacity: 0 } : { opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <Footer />
    </motion.div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transitionStatus: PropTypes.string,
};

Layout.defaultProps = {
  transitionStatus: ENTERING,
};

export default Layout;
