import * as React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ title }) => {
  return <h1 className="script text-6xl md:text-8xl my-6">{title}</h1>;
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
