import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { EXITING } from "../constants/transition-states";

const initialState = { opacity: 0, y: 10 };

const Container = ({ children, transitionStatus, entry, exit }) => (
  <motion.div
    initial={entry.state.opacity ? entry.state : initialState}
    animate={transitionStatus === EXITING ? exit.state : { opacity: 1, y: 0 }}
    transition={
      transitionStatus === EXITING
        ? { duration: exit.length }
        : { duration: 0.4 }
    }
  >
    <div className="container mx-auto text-center">{children}</div>
  </motion.div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  transitionStatus: PropTypes.string.isRequired,
  entry: PropTypes.instanceOf(Object).isRequired,
  exit: PropTypes.instanceOf(Object).isRequired,
};

export default Container;
