import * as React from "react";
import PropTypes from "prop-types";

const Body = ({ children }) => {
  return <div className="w-full xl:w-8/12 mx-auto px-6">{children}</div>;
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
