import React from 'react';
import facebook from '../images/facebook.svg';
import messenger from '../images/messenger.svg';
import email from '../images/email.svg';
import telephone from '../images/telephone.svg';
import { motion } from 'framer-motion';

const socials = [
  {
    link: 'https://www.facebook.com/Halohairderbyshire',
    icon: facebook,
    alt: 'Facebook Icon',
  },
  {
    link: 'https://m.me/Halohairderbyshire',
    icon: messenger,
    alt: 'Messenger Icon',
  },
  {
    link: 'mailto:leanne.jane.wright@gmail.com',
    icon: email,
    alt: 'Email Icon',
  },
  {
    link: 'tel:+447875403292',
    icon: telephone,
    alt: 'Telephone Icon',
  },
];

const Footer = () => (
  <footer className='absolute w-full bottom-0 h-36 md:h-24 flex flex-col justify-end'>
    <div className='footer-background bg-repeat-x bg-top w-full h-4' />
    <div className='bg-white pb-2'>
      <div className='container mx-auto p-6 flex flex-col md:flex-row justify-between items-center'>
        © {new Date().getFullYear()} Halo Hair Derbyshire
        <div className='flex'>
          {socials.map((social) => (
            <motion.div
              whileHover={{ scale: 1.2 }}
              className='mt-6 md:mt-0 first:ml-0 ml-10 md:ml-8'
              key={social.alt}
            >
              <a href={social.link}>
                <img
                  className='h-7 md:h-5 opacity-90'
                  src={social.icon}
                  alt={social.alt}
                />
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
